import React, { useEffect, useRef } from "react";
import { useSearchBox } from "react-instantsearch-hooks-web";
import { BsSearch } from "react-icons/bs";
import { iOSUserAgent } from "utils/browsers";

import styles from "./CustomSearchBox.module.scss";

const CustomSearchBox = ({
  queryHook,
  placeholder,
  style,
  onClick,
  onChange,
  initialOpen,
  resultsActive,
}) => {
  const { query, refine, clear } = useSearchBox({
    queryHook,
  });

  useEffect(() => {
    if (query || resultsActive) {
      return;
    }

    localInputRef.current.value = "";
    clear();
  }, [resultsActive, clear, query]);

  const localInputRef = useRef();

  useEffect(() => {
    if (!!initialOpen) {
      localInputRef.current.focus();
    }
  }, []);

  const handleChange = (event) => {
    refine(event.target.value);
    onChange ? onChange(event) : "";
  };

  const handleResetClick = (event) => {
    clear();
    localInputRef.current.value = "";
  };

  return (
    <div className={`${styles.searchBoxContainer}`}>
      <div className={styles.submitIcon}>
        <BsSearch />
      </div>
      <input
        type="text"
        className={`${styles.searchBox} ${styles.rounded} header-searchbox`}
        style={style}
        placeholder={placeholder}
        onClick={onClick}
        onChange={handleChange}
        ref={localInputRef}
      />
      {query && (
        <div className={styles.resetIcon} onClick={handleResetClick}>
          X
        </div>
      )}
    </div>
  );
};

export const CustomSearchBoxDummy = ({ placeholder, style, onClick }) => {
  const localInputRef = useRef();

  return (
    <div className={`${styles.searchBoxContainer}`}>
      <div className={styles.submitIcon}>
        <BsSearch />
      </div>
      <input
        type="text"
        className={`${styles.searchBox} ${styles.rounded} header-searchbox`}
        style={style}
        placeholder={placeholder}
        onClick={onClick}
        ref={localInputRef}
      />
      <div className={styles.resetIcon}></div>
    </div>
  );
};

export default CustomSearchBox;
